import React from 'react'
import { useState, useEffect } from 'react'
import Container from '../../Components/Container'
import toast, { Toaster } from 'react-hot-toast';
import { FaSearch } from "react-icons/fa";
import { IoReload } from "react-icons/io5";

const İmportHistory = () => {
    const [date, setDate] = useState()
    const [data, setData] = useState([])
    const [barcode, setBarcode] = useState('')
    const [barcodeData, setBarcodeData] = useState([])

    const [dateData, setDateData] = useState([])
    useEffect(() => {
        const date = new Date().getDate();
        if (date) {
            fetchData()
        }
    }, [date]);


    const fetchData = () => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        formdata.append('date', date)
        fetch(process.env.REACT_APP_BASE_URL + 'storeHistory.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 200) {
                    toast.error('Veri bulunamdı');
                } else {
                    setData(data.data)
                }
            })
            .catch(e => {
                throw new Error(e);

            })
    }


    const fetchBarcode = () => {

        if (!barcode) {
            toast.error('Lütfen bir barkod giriniz')
            return
        }
        const formdata = new FormData()
        formdata.append('action', 'selectWithBarcode')
        formdata.append('barcode', barcode)
        fetch(process.env.REACT_APP_BASE_URL + 'storeHistory.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 200) {
                    toast.error('Veri bulunamdı');
                } else {
                    setBarcodeData(processPurchaseData(data.data))
                    setDateData(data.data)
                }
            })
            .catch(e => {
                throw new Error(e);

            })
    }

    const processPurchaseData = (data) => {

        const totalCount = data.reduce((sum, item) => sum + Number(item.count), 0);

        // Ortalama satın alım fiyatı hesapla
        const totalCost = data.reduce((sum, item) => sum + Number(item.cost), 0);
        const averageCost = totalCost / data.length;

        // Tarihe göre kaç tane alındığını hesapla
        const purchasesByDate = data.reduce((acc, item) => {
            if (!acc[item.date]) {
                acc[item.date] = 0;
            }
            acc[item.date] += Number(item.count);
            return acc;
        }, {});

        const item = {
            'name': data[0].name,
            'barcode': data[0].barcode,
            'currentCost': data[0].cost,
            'image': data[0].image,
        }
        return { totalCount, averageCost, purchasesByDate, item };
    };


    const keyHandel = (e) => {

        if (e.keyCode == 13 || e.key == 'Enter'){
            fetchBarcode()
        }

    }


    return (
        <Container padding  >
            <Toaster position='top-right'></Toaster>
            <div className='w-full flex flex-col items-center justify-end gap-2'>
                
                <div className='w-full flex items-center justify-center gap-4'>
                    <input value={barcode} onKeyDown={(e) => { keyHandel(e) }} onChange={(e) => { setBarcode(e.target.value) }} placeholder='Barkod ile ara' className='w-full border p-2 h-9  border-black rounded-md '></input>
                    <button onClick={() => { fetchBarcode() }} className=' bg-green-400 flex items-center justify-center gap-1 text-white rounded-md px-6  p-2'>
                        <FaSearch></FaSearch> Ara
                    </button>
                    {
                        barcodeData && Object.keys(barcodeData).length > 0 && <button onClick={() => {
                            setBarcodeData([])
                            setDateData([])
                        }} className=' bg-indigo-600 flex items-center group justify-center gap-1 text-white rounded-md px-6  p-2'>
                            <IoReload className='group-hover:animate-spin' /> Sıfırla
                        </button>
                    }
                </div>
            </div>
            <div className='w-full flex items-start justify-start gap-4 flex-wrap'>
                {
                    barcodeData.length <= 0 && data && data.map((item) => {
                        return (
                            <div className='w-full md:max-w-80 flex flex-col rounded-md overflow-hidden   items-start justify-start ' >
                                <img src={item.image}></img>
                                <span className=' p-2 grid grid-cols-1 md:grid-cols-2 rounded-md rounded-t-none border border-t-0 border-black w-full   gap-4'>
                                    <span className='flex md:flex-row flex-col md:items-center justify-start gap-1 w-full whitespace-normal'>
                                        <p className='font-bold whitespace-nowrap'>Ürün Adı :</p>
                                        <p className=' items-center'>{item.name}</p>
                                    </span>
                                    <span className='flex md:flex-row flex-col md:items-center justify-start gap-1 w-full whitespace-normal'>
                                        <p className='font-bold whitespace-nowrap'>Ürün barkodu :</p>
                                        <p className=' items-center'>{item.barcode}</p>
                                    </span>
                                    <span className='flex md:flex-row flex-col md:items-center justify-start gap-1 w-full whitespace-normal'>
                                        <p className='font-bold whitespace-nowrap'>Ürün Adeti :</p>
                                        <p className=' items-center'>{item.count}</p>
                                    </span>
                                    <span className='flex md:flex-row flex-col md:items-center justify-start gap-1 w-full whitespace-normal'>
                                        <p className='font-bold whitespace-nowrap'>Satın alım fiyatı:</p>
                                        <p className=' items-center'>{item.cost}</p>
                                    </span>
                                    <span className='flex md:flex-row flex-col md:items-center justify-start gap-1 w-full whitespace-normal'>
                                        <p className='font-bold whitespace-nowrap'>Satın alım tarihi:</p>
                                        <p className=' items-center'>{item.date}</p>
                                    </span>

                                </span>
                            </div>
                        )
                    })
                }

                {
                    barcodeData && Object.keys(barcodeData).length > 0 && (
                        <div className='w-full flex flex-col md:flex-row items-start justify-start h-screen gap-4'>
                            {/* Ürün Bilgileri (Sol Taraf) */}
                            <div className='w-full md:w-1/2 flex flex-col items-start justify-start bg-white shadow-md p-4 rounded-lg'>
                                <img src={barcodeData.item.image} alt="Ürün Resmi" className='w-full h-auto rounded-md' />
                                <span className='text-lg font-bold mt-4'>{barcodeData.item.name}</span>
                                <p className='text-gray-700'>Barkod: <span className='font-semibold'>{barcodeData.item.barcode}</span></p>
                                <p className='text-gray-700'>Güncel Fiyat: <span className='font-semibold'>{barcodeData.item.currentCost}₺</span></p>
                                <p className='text-gray-700'>Toplam Adet: <span className='font-semibold'>{barcodeData.totalCount}</span></p>
                            </div>

                            {/* Tarihe Göre Satın Alım (Sağ Taraf) */}
                            <div className='w-full md:w-1/2 flex flex-col bg-white shadow-md p-4 rounded-lg'>
                                <h3 className='text-lg font-bold mb-2'>Tarihe Göre Satın Alım</h3>
                                <ul className='w-full border rounded-md p-2'>
                                    {dateData.map((item) => (
                                        <li key={date} className='flex justify-between items-center border-b py-2 px-2'>
                                            <span className='text-gray-700'>{item.date}</span>
                                            <span className='font-semibold'>{item.count} adet</span>
                                            <span className='text-green-600 font-semibold'>{item.cost}₺</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )
                }



            </div>

        </Container>
    )
}

export default İmportHistory