import React, { useEffect } from 'react'

const SalesCard = ({ func, value, item }) => {

    const deleteItem = () => {
        
        const itemCount = value.filter((i) => i.barcode === item.barcode).length
        if (itemCount >= 1) {
            const index = value.findIndex((i) => i.barcode === item.barcode)
            value[index].count -= 1
            func([...value])
        }
        if (item.count <= 0) {

            func(value.filter((i) => i.barcode !== item.barcode))
        }

    }
    return (
        <div className='w-full bg-white p-4 rounded-md shadow-md'>
            <img src={item.image} className='w-full h-48 object-cover rounded-md' alt="" />
            <div className='w-full flex-col flex items-start justify-between mt-2 gap-2'>
                <span className='w-full flex items-center justify-between'>
                   <span className='w-full flex flex-col items-start justify-start'>
                   <h1 className='text-lg font-semibold text-gray-800'>{item.barcode}</h1>
                   <h2 className='text-base font-semibold text-gray-800'>{item.name}</h2>
                   </span>
                    <p className='text-sm font-semibold text-gray-500'>{item.price}TL</p>
                </span>
                
                    <details className='flex items-end justify-end'>
                        <ul>
                            <li>
                                Maliyet : {item.cost}
                            </li>
                            <li>
                                Stok adeti: {item.count}
                            </li>
                            
                        </ul>
                    </details>
                
            </div>
            <div className='w-full flex flex-col items-start gap-2 justify-between mt-2'>
                <p className='text-sm font-semibold text-gray-500'>x{item.count} adet</p>
                <button onClick={(e) => { deleteItem() }} className='bg-red-500 text-white rounded-md p-2'>Sepetten Çıkar</button>
            </div>
        </div>
    )
}

export default SalesCard