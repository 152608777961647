import React, { useEffect, useState } from 'react'
import Container from '../../Components/Container'
import toast, { Toaster } from 'react-hot-toast'

const Store = () => {

    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [phone, setPhone] = useState('')
    const [capasity, setCapasity] = useState('')

    const [items, setItems] = useState([])

    const [id, setİd] = useState();
    const [update, setUpdate] = useState(false)

    const updateHandel = (item) => {
        setUpdate(true)
        setOpen(true)
        setİd(item.id)
        setName(item.name)
        setLocation(item.location)
        setPhone(item.phone)
        setCapasity(item.capacity)
    }

    const fetchHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'update')
        formdata.append('id', id)
        formdata.append('name', name)
        formdata.append('location', location)
        formdata.append('capacity', capasity)
        formdata.append('phone', phone)
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                    setOpen(false)
                    fetchData()
                } else {
                    setOpen(false)
                    toast.error(data.message)
                }
            })
            .catch(e => console.error(e))

    }


    const fetchData = () => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setItems(data)
            })
    }
    useEffect(() => {
        fetchData()
    }, [])






    const toggleHandeler = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }

    const submitHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'insert')
        formdata.append('name', name)
        formdata.append('location', location)
        formdata.append('capacity', capasity)
        formdata.append('phone', phone)
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                    fetchData()
                } else {
                    toast.error(data.message)
                }
            })
            .catch(e => console.error(e))

    }

    const deleteHandel = (item) => {

        const formdata = new FormData()
        formdata.append('action', 'delete')
        formdata.append('id', item.id)
        formdata.append('name', item.name)
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                    fetchData()
                    setOpen(false)
                } else {
                    toast.error(data.message)
                }
            })
            .catch(e => console.error(e))
    }


    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            {
                open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        <h1 className='text-2xl font-semibold text-gray-700'>Yeni Depo Ekle</h1>

                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Depo Adı</label>
                            <input onChange={(e) => { setName(e.target.value) }} value={name} type='text' id='name' placeholder='Depo Adı' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='location' className='text-gray-700 w-24'>Konum</label>
                            <input onChange={(e) => { setLocation(e.target.value) }} value={location} type='text' placeholder='Konum' id='location' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='count' className='text-gray-700 w-24'>Kapasite</label>
                            <input onChange={(e) => { setCapasity(e.target.value) }} value={capasity} type='number' placeholder='Kapasite' id='count' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='phone' className='text-gray-700 w-24'>Telefon</label>
                            <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type='tel' placeholder='Telefon' id='phone' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        {
                            update == false && <div className='w-full flex items-center justify-end'>
                                <button onClick={() => { submitHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                            </div>
                        }
                        {
                            update && <div className='w-full flex items-center justify-end'>
                                <button onClick={() => { fetchHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Güncelle</button>
                            </div>

                        }

                    </div>
                </div>
            }
            <div className='w-full flex items-center justify-between'>
                <h1 className='text-2xl font-semibold text-gray-700'>Depo</h1>
                <button onClick={() => { setOpen(true) }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Depo Ekle</button>
            </div>
            {/* <div className='w-full flex items-center justify-center'>
                <input type="text" placeholder='Ara...' className='w-full p-2 border border-gray-300 rounded-md' />
            </div> */}
            <div className='w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 '>

                {
                    items.map((item) => {
                        return (
                            <div key={item.id} className='w-full flex flex-col p-2 shadow-md rounded-md  items-center justify-center gap-4'>

                                <div className='w-full flex-col items-start justify-between gap-4'>
                                    <h1 className='text-xl font-semibold text-gray-700'>{item.name}</h1>
                                    <p className='text-gray-500'>{item.location}</p>
                                    <a href={`tel:${item.phone}`} className='text-gray-500 text-sm'>{item.phone}</a>
                                    <div className='w-full flex items-center justify-center gap-4'>
                                        <button onClick={() => { updateHandel(item) }} className='w-full bg-indigo-400 text-white px-4 py-2 rounded-md'>Düzenle</button>
                                        <button onClick={() => { deleteHandel(item) }} className='w-full bg-red-400 text-white px-4 py-2 rounded-md'>Sil</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </Container>
    )
}

export default Store