import React, { useEffect, useSyncExternalStore } from 'react'
import Container from '../../Components/Container'
import DataTable from 'react-data-table-component';
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';

const StoreDetail = () => {


  const [open, setOpen] = useState(false)
  const [item, setItem] = useState([])
  const [data, setData] = useState([])
  const [barcode, setBarcode] = useState()
  const [selectedStore, setSelectedStore] = useState()
  const [selectedR, setSelectedRows] = useState([])
  const [update, setUpdate] = useState(false)
  const [name, setName] = useState()
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(1)
  const [id, setID] = useState()







  useEffect(() => {
    const formdata = new FormData()
    formdata.append('action', 'select')
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    })
      .then(res => res.json())
      .then(data => {
        setData(data)
        setPending(false)
      }).finally(() => { })
      .catch(setPending(true))


    const storeFetch = new FormData()
    storeFetch.append('action', 'select')
    fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
      method: 'POST',
      body: storeFetch
    }).then(res => res.json())
      .then(data => {
        setItem(data)
      })
  }, [])

  const submitHandel = () => {
    if (id === 'undefined') {
      return false
    }
    const formdata = new FormData()
    formdata.append('action', 'storeUpdate')
    formdata.append('id', id)
    formdata.append('barcode', barcode)
    formdata.append('store', selectedStore)
    formdata.append('count', count)
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.json())
      .then(data => {
        if (data.status != 400) {
          toast.success(data.message)
          setOpen(false)
        } else {
          toast.error(data.message)
          setOpen(false)
        }
      })

  }

  const transferHandel = (row) => {
    setOpen(true)
    setUpdate(false)
    setBarcode(row.barcode)
    setID(row.id)
    console.log(row.id)
  }

  const toggleHandeler = (e) => {
    e.preventDefault()
    if (e.target.classList.contains('close')) {
      setOpen(false)
    }
  }


  const columns = [
    {
      name: 'No',
      selector: row => row.id,
      sortable: true,
    },
    {
      name:'Barkod',
      selector:row=>row.barcode,

    },
    {
      name: 'Ürün Adı',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Depo Adı',
      selector: row => row.store,
      sortable: true,
    },
    {
      name: 'Adet',
      selector: row => row.count,
      sortable: true,
    },
    {
      name: 'Maliyet',
      selector: row => row.cost,
      sortable: true

    },
    {
      name: 'Fiyat',
      selector: row => row.price,
      sortable: true

    },

    {
      name: 'İşlem',
      cell: row =>
        <div className='w-full flex items-center flex-col lg:flex-row py-2 justify-start gap-2'>
          <button onClick={() => { transferHandel(row) }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Aktar</button>
        </div>,
      align: 'right',
    }
  ];



  const multiTransferHandel = () => {


    if (selectedR.length <= 0) {
      toast.error('En az bir ürün seçiniz');
      return
    } else {
      setUpdate(true)
      setOpen(true)
    }
  }

  const filterByname = () => {

    if (name != null) {
      const formdata = new FormData()
      formdata.append('action', 'findByBarcodeForStore')
      formdata.append('barcode', name)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json()).then(data => {
        setData(data.data)
      })
    } else {
      const formdata = new FormData()
      formdata.append('action', 'select')
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      })
        .then(res => res.json())
        .then(data => {
          setData(data)
        })
    }



  }


  const fetchHandel = () => {
    const formdata = new FormData()
    formdata.append('action', 'multi')
    formdata.append('store', selectedStore)
    formdata.append('selected', JSON.stringify(selectedR))
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {
        if (data.status != 400) {
          toast.success(data.message)
        } else {
          toast.error(data.message)
        }
      })
  }



  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows)
  };


  const Loader = () => {
    return (
      <div className='w-full flex items-center justify-center flex-col gap-4'>
        <div class="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600 mt-20" />
        <h1>Ürünler yükleniyor lütfen bekleyiniz.</h1>
      </div>
    )
  }
  return (
    <Container>
      <Toaster position='bottom-center'></Toaster>
      {
        open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
          <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
            <h1 className='text-2xl font-semibold text-gray-700'>Depo seçin</h1>
            <span className='w-full flex items-center justify-between gap-4 '>
              <label htmlFor="product" className='text-gray-700'>Depo</label>
              <select onChange={(e) => { setSelectedStore(e.target.value) }} value={selectedStore} name="product" id="product" className='p-2 border w-full border-gray-300 rounded-md'>
                {
                  item.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>{item.name}</option>
                    )
                  })
                }
              </select>
            </span>
            <span className='w-full flex items-center justify-between gap-4 '>
              <label htmlFor="" className='text-gray-700'>Adet</label>
              <input type='text' value={count} onChange={(e) => { setCount(e.target.value) }} placeholder='Ürün adet giriniz' className='w-full p-2 border border-gray-300 rounded-md' />

            </span>

            <div className='w-full flex items-center justify-end'>
              {
                !update && <button onClick={() => { submitHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
              }
              {
                update && <button onClick={() => { fetchHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Aktar</button>
              }
            </div>
          </div>
        </div>
      }
      <div className='w-full flex items-center justify-between'>
        <h1 className='text-2xl font-semibold text-gray-700'>Tüm ürünler</h1>
        <button onClick={() => { multiTransferHandel() }} className='bg-blue-500 text-white px-4 py-2 rounded-md'>Aktar</button>
      </div>
      <span className='w-full flex items-center gap-3 justify-center'>
        <input type='text' onKeyDown={(e) => { }} value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Ürün barkod giriniz' className='w-full p-2 border border-gray-300 rounded-md' />
        <button onClick={() => { filterByname() }} className='bg-green-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
          Ara
        </button>
      </span>
      <DataTable
        columns={columns}
        selectableRows
        pagination
        progressPending={pending}
        progressComponent={<Loader></Loader>}
        onSelectedRowsChange={handleChange}
        data={data}
      ></DataTable>
    </Container>
  )
}

export default StoreDetail