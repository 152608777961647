import React from 'react'
import Container from '../../Components/Container'
import { useEffect, useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';





const Daily = () => {

    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [total, setTotal] = useState(0)
    const [count, setCount] = useState([])
    const [cat, setCat] = useState([])
    const [stores, setStores] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState()
    const [date, setDate] = useState(new Date().toLocaleDateString())
    const [category, setCategory] = useState('')
    const [store, setStore] = useState('')
    const [order, setOrder] = useState(false)
    useEffect(() => {
        const date = new Date();
        if (date) {
            fetchData()
        }
    }, [date]);


    useEffect(() => {
        setData(data.reverse())
    }, [order])


    const fetchData = () => {
        setLoading(true)
        const formdata = new FormData()
        formdata.append('action', 'selectDay')
        formdata.append('date', date)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                setData([])
                setData(data)
                const s = data.filter(i => i.type == 1).length
                const r = data.filter(i => i.type == 0).length
                const c = data.filter(i => i.type == 2).length
                setCount([s, r, c])
                setLoading(false)
            })

    }

    // useEffect(() => {
    //     fetchData()
    // }, [date])

    useEffect(() => {
        const categories = new FormData()
        categories.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'categories.php', {
            method: 'POST',
            body: categories
        }).then(res => res.json()).then(data => {
            setCat(data)
        })

        const stores = new FormData()
        stores.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: stores
        }).then(res => res.json()).then(data => {
            setStores(data)
        })


    }, [])


    const filterHandel = (e) => {
        const dataAtt = e.target.dataset.t;
        const allItems = document.querySelectorAll('[data-type]');

        allItems.forEach(item => {
            const d = item.dataset.type
            if (d != dataAtt) {
                item.classList.add('hidden')
            } else {


                item.classList.remove('hidden')

            }
        })
    }

    const filterByCategories = (e) => {

        setCategory(e.target.value)

        if (e.target.value != 0) {
            const formdata = new FormData()
            formdata.append('action', 'selectByCategory')
            formdata.append('date', date)
            formdata.append('store', store)
            formdata.append('category', e.target.value)
            fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
                method: 'POST',
                body: formdata
            }).then(res => res.json())
                .then(data => {
                    setPrice(data[0].price)
                    setCost(data[0].cost)
                    setTotal(data[0].count)
                })
            const allItems = document.querySelectorAll('[data-category]');

            allItems.forEach(item => {

                const d = item.dataset.category

                if (d != e.target.value) {
                    item.classList.add('hidden')
                } else {
                    item.classList.remove('hidden')

                }

            })
        }
    }
    const filterByStore = (e) => {

        setStore(e.target.value)

        if (e.target.value != 0) {


            const formdata = new FormData()
            formdata.append('action', 'selectByStore')
            formdata.append('date', date)
            formdata.append('store', e.target.value)
            fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
                method: 'POST',
                body: formdata
            }).then(res => res.json())
                .then(data => {
                    setPrice(data[0].price)
                    setCost(data[0].cost)
                    setTotal(data[0].count)
                })

            const allItems = document.querySelectorAll('[data-store]');

            allItems.forEach(item => {

                const d = item.dataset.store

                if (d != e.target.value) {
                    item.classList.add('hidden')
                } else {
                    item.classList.remove('hidden')

                }

            })
        }

    }



    const deleteHandel =(item)=>{
        setLoading(true)
        const formdata = new FormData()
        formdata.append('action', 'cancel')
        formdata.append('id',item.id);
        formdata.append('store',item.store)
        formdata.append('barcode',item.barcode)
        formdata.append('count',item.count)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if(data.status != 200){
                    toast.error(data.message)
                }else {
                    fetchData()
                    setLoading(false)
                }
            })

    }





    return (
        <Container>
            <Toaster position='top-right'></Toaster>
            <div className='w-full flex items-start justify-between shadow-black/20 shadow-md  p-2 rounded-md '>
                <span className='w-full flex flex-col items-start justify-start gap-2'>
                    <h1 className='lg:text-2xl text-base whitespace-nowrap text-black font-bold'>
                        Günlük satılanlar
                    </h1>
                </span>
                <span className='w-full flex flex-col items-end justify-end gap-1'>
                    <p className='text-lg font-bold'>
                        {date}
                    </p>
                    <input type='date' value={date} className='' onChange={(e) => { setDate(e.target.value) }}></input>
                </span>
            </div>
            {
                data && data.length > 0 && <div className='w-full flex p-4 items-center justify-center shadow-md shadow-black/50 rounded-md'>
                    <span className='w-full flex items-center justify-center gap-4'>
                        <span className='w-full text-center flex items-center justify-center gap-4'>
                            <p>
                                Toplam Satış {count[0]}
                            </p>
                        </span>
                        <span className='w-full text-center flex items-center justify-center gap-4'>
                            <p>
                                Toplam İade {count[1]}
                            </p>
                        </span>
                        <span className='w-full text-center flex items-center justify-center gap-4'>
                            <p>
                                Toplam Zaiat {count[2]}
                            </p>
                        </span>
                    </span>
                </div>
            }
            {
                data && data.length > 0 && <div className='w-full flex p-4 items-center justify-center shadow-md shadow-black/50 rounded-md'>
                    <span className='w-full flex items-center justify-center gap-4'>
                        <span className='w-full text-center flex flex-col items-center justify-center gap-4'>
                            <p>
                                Toplam Satış
                            </p>
                            <p>
                                {price}
                            </p>
                        </span>
                        <span className='w-full text-center flex-col border-x border-black flex items-center justify-center gap-4'>
                            <p>
                                Toplam Maliyet
                            </p>
                            <p>
                                {cost}
                            </p>
                        </span>
                        <span className='w-full text-center flex-col flex items-center justify-center gap-4'>
                            <p>
                                Toplam Adet
                            </p>
                            <p>
                                {total}
                            </p>
                        </span>
                    </span>
                </div>
            }

            {
                data.length > 0 && <div className='w-full flex items-center gap-6 justify-between shadow-black/20 shadow-md  p-2 rounded-md '>
                    <button onClick={(e) => { filterHandel(e) }} data-t='1' className='w-full flex items-center justify-center text-white p-3 rounded-md bg-indigo-500'>Satış</button>
                    <button onClick={(e) => { filterHandel(e) }} data-t='0' className='w-full flex items-center justify-center text-white p-3 rounded-md bg-indigo-500'>İade</button>
                    <button onClick={(e) => { filterHandel(e) }} data-t='2' className='w-full flex items-center justify-center text-white p-3 rounded-md bg-indigo-500'>Zaiat</button>
                </div>
            }
            {
                data && data.length > 0 && <div className='w-full flex items-start justify-start flex-col gap-6 shadow-black/20 shadow-md  p-2 rounded-md '>
                    <span className='w-full flex flex-col items-start justify-start gap-4 '>
                        <label htmlFor='name' className='text-gray-700 w-24'>Depo</label>
                        <select value={store} onChange={(e) => { filterByStore(e) }} className='p-2 border w-full border-gray-300 rounded-md'>
                            <option value={0}>Depo Seçiniz</option>
                            {
                                stores.map((store, index) => {
                                    return <option value={store.name} key={index}>{store.name}</option>
                                })
                            }
                        </select>
                    </span>
                    {
                        store != 0 && <span className='w-full flex flex-col items-start justify-start gap-4 '>
                            <label htmlFor='categories' className='text-gray-700 w-24'>Kategori</label>
                            <select value={category} onChange={(e) => { filterByCategories(e) }} className='p-2 border w-full border-gray-300 rounded-md'>
                                <option value={0}>Kategori Seçiniz</option>
                                {
                                    cat.map((category, index) => {
                                        return <option value={category.name} key={index}>{category.name}</option>
                                    })
                                }
                            </select>
                        </span>
                    }
                </div>
            }

            <div className='w-full flex items-center p-5 justify-end shadow-md shadow-black/20 rounded-md'>
                {
                    order != false ? <p className='w-full gap-4 flex items-center justify-start p-3 font-bold'>En Yeni <FaArrowUp></FaArrowUp></p> : <p className='w-full flex gap-4 items-center justify-start p-3 font-bold'>En Eski <FaArrowUp className='rotate-180'></FaArrowUp></p>
                }
                <button onClick={() => { setOrder(!order) }} className='bg-indigo-500 rounded-md p-4 px-6 text-white'>Sırala</button>
            </div>

            <div id='container' className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 place-items-start'>
                {
                    loading != true ? data.map((item, index) => {
                        return (
                            <div
                                style={item.type != 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ff6136' }}
                                key={index} data-type={item.type} data-store={item.store} data-category={item.category} className='w-full bg-white p-4 rounded-md shadow-md'>

                                <img src={item.image != null ? item.image : 'https://placehold.co/600x400'} className='w-full h-48 object-cover rounded-md' alt="" />
                                <div className='w-full grid grid-cols-2 gap-4 p-4 text-center'>
                                    <h1 style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm capitalize font-semibold text-gray-800'>{item.name}</h1>
                                    <h4 style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm font-semibold text-gray-800'>Barkod :{item.barcode}</h4>
                                    <p style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm font-semibold text-gray-500'>{parseInt(item.price).toFixed(2)} TL</p>
                                    <p style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm font-semibold text-gray-500'>{item.count} Adet</p>
                                    <p style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm font-semibold text-gray-500'>Kullanıcı :{item.user} </p>
                                    <p style={item.type == 1 ? { color: 'black' } : { color: 'black' }} className='text-sm font-semibold text-gray-500'>Tarih :{item.date} </p>
                                </div>

                                <div className='w-full flex  items-center gap-2 justify-between mt-2'>
                                    {item.type == 0 && <p className='w-full text-white  p-4 text-base font-bold'>İşlem Türü :İade</p>}
                                    <button onClick={()=>{deleteHandel(item)}} className=' w-full flex items-center justify-center text-white p-3 bg-indigo-600 rounded-md'>İptal</button>
                                </div>
                            </div>
                        )
                    }) : null
                }
            </div>


        </Container>
    )
}

export default Daily